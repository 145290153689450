import { all, call, fork } from 'redux-saga/effects'
import general from './general/saga'
import user from './user/saga'
import cart from './cart/saga'

export default function* rootSaga() {
  try {
    yield all([general, user, cart].map(saga => call(saga)))
  } catch (error) {
    yield fork(rootSaga)
  }
}
