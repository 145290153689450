import(/* webpackMode: "eager", webpackExports: ["Collapse"] */ "__barrel_optimize__?names=Collapse!=!/workspace/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workspace/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/local/target.css?{\"path\":\"src/fonts/font.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./Myriad Variable Concept.ttf\"}],\"variableName\":\"Myriad\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/local/target.css?{\"path\":\"src/fonts/font.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./Sergio-Trendy-Regular.ttf\"}],\"variableName\":\"Sergio\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/app/StoreProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/layout/cart.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/layout/sideMenu.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/menu/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/menu/socialLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/shared/Icon.js");
