import { createActions } from 'redux-actions'

export default createActions(
  'FETCH_CART',
  'FETCH_CART_SUCCESS',
  'FETCH_CART_FAIL',
  'APPLY_DISCOUNT',
  'APPLY_DISCOUNT_SUCCESS',
  'APPLY_DISCOUNT_FAIL',
  'ADD_ITEM',
  'ADD_ITEM_SUCCESS',
  'ADD_ITEM_FAIL',
  'UPDATE_ITEM',
  'UPDATE_ITEM_SUCCESS',
  'UPDATE_ITEM_FAIL',
  'REMOVE_ITEM',
  'REMOVE_ITEM_SUCCESS',
  'REMOVE_ITEM_FAIL',
  {
    prefix: 'CART'
  }
)
