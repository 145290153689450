import { handleActions } from 'redux-actions'
import actions from './action'
import userStorage from '@/lib/api/userStorage'

const initialState = {
  message: null
}

export default handleActions(
  {
    [actions.refreshTokenSuccess]: (state, { payload }) => {
      const storage = userStorage()
      storage.clientToken = payload
      return state
    },
    [actions.showMessage]: (state, { payload }) => {
      return {
        ...state,
        message: payload
      }
    }
  },
  initialState
)
