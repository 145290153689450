import { handleActions } from 'redux-actions'
import actions from './action'

const initialState = {
  expand: [1],
  themeFilters: {}
}

export default handleActions(
  {
    [actions.setExpandFilter]: (state, { payload }) => {
      return {
        ...state,
        expand: payload
      }
    },
    [actions.setFilter]: (state, { payload }) => {
      const { key, value } = payload
      const themeFilters = JSON.parse(JSON.stringify(state.themeFilters))
      if (!themeFilters[key]) themeFilters[key] = []
      if (themeFilters[key].includes(value))
        themeFilters[key] = themeFilters[key].filter(v => v !== value)
      else themeFilters[key] = [...themeFilters[key], value]
      return {
        ...state,
        themeFilters: { ...themeFilters }
      }
    },
    [actions.clearFilter]: (state, { payload }) => {
      const { key } = payload
      const themeFilters = JSON.parse(JSON.stringify(state.themeFilters))
      themeFilters[key] = []
      return {
        ...state,
        themeFilters: { ...themeFilters }
      }
    }
  },
  initialState
)
