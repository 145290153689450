import { createActions } from 'redux-actions'

export default createActions(
  'REFRESH_TOKEN',
  'REFRESH_TOKEN_SUCCESS',
  'REFRESH_TOKEN_FAIL',
  'SHOW_MESSAGE',
  {
    prefix: 'GENERAL'
  }
)
