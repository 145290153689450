import Request from '@/lib/api/request'
import userStorage from '@/lib/api/userStorage'

export const fetchCartData = async () => {
  const response = await Request.post('checkout/fetch-cart')
    .useAuth()
    .useCart()
    .send()
  return response
}

export const updateDiscount = async ({ discountCodes }) => {
  const storage = userStorage()
  const response = await Request.post('checkout/update-discount-codes')
    .body({
      cartId: storage.cartId,
      discountCodes
    })
    .useAuth()
    .send()
  return response
}

export const addProduct = async ({ image, id, sku, amount }) => {
  const storage = userStorage()
  const response = await Request.post('checkout/add-cart-lines')
    .body({
      cartId: storage.cartId,
      lines: [
        {
          userImageId: image,
          skuId: id,
          sku: sku,
          quantity: amount
        }
      ]
    })
    .useAuth()
    .send()
  return response
}

export const updateProduct = async ({ bundleId, quantity }) => {
  const storage = userStorage()
  const response = await Request.post('checkout/update-cart-lines')
    .body({
      cartId: storage.cartId,
      lines: [
        {
          bundleId,
          quantity
        }
      ]
    })
    .useAuth()
    .send()
  return response
}

export const removeProduct = async ({ bundleId }) => {
  const storage = userStorage()
  const response = await Request.post('checkout/remove-cart-lines')
    .body({
      cartId: storage.cartId,
      lines: [
        {
          bundleId
        }
      ]
    })
    .useAuth()
    .send()
  return response
}
