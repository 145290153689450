import { all, put, takeLeading } from 'redux-saga/effects'
import actions from './action'
import { refreshTokenAPI } from './api'

function* refreshToken() {
  try {
    const result = yield refreshTokenAPI()
    if (result.status === 200) {
      yield put(actions.refreshTokenSuccess(result.data.data.clientToken))
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* root() {
  yield all([takeLeading(actions.refreshToken.toString(), refreshToken)])
}
