'use client'

import { usePathname } from 'next/navigation'
import Link from 'next/link'

export default function MenuLink({ item, text, click }) {
  const pathname = usePathname()
  return (
    <Link
      href={item.link}
      target={item.target}
      className={`py-5 md:py-0 ${pathname === item.link || `/${pathname.split('/')[1]}` === item.link ? 'menu-active-mobile md:menu-active-desktop' : ''}`}
      onClick={() => {
        if (click) click()
      }}
      title={text}
    >
      {text}
    </Link>
  )
}
