import { all, put, takeLeading } from 'redux-saga/effects'
import actions from './action'
import {
  uploadUserPhoto,
  fetchUserUpload,
  fetchPhotoProgress,
  genUserPhoto
} from './api'

function* uploadPhoto({ payload }) {
  try {
    const { photo } = payload
    const result = yield uploadUserPhoto(photo)
    if (result.status === 200) {
      yield put(actions.uploadPhotoSuccess())
    }
  } catch (error) {
    console.log(error)
  }
}

function* fetchUpload() {
  try {
    const result = yield fetchUserUpload()
    if (result.status === 200) {
      yield put(actions.fetchUploadSuccess(result.data.data.list))
    }
  } catch (error) {
    console.log(error)
  }
}

function* generatePhoto({ payload }) {
  try {
    const result = yield genUserPhoto({ ...payload })
    if (result.status === 200) {
      yield put(actions.generatePhotoSuccess())
    }
  } catch (error) {
    console.log(error)
  }
}

function* fetchProgress() {
  try {
    const result = yield fetchPhotoProgress()
    if (result.status === 200) {
      yield put(actions.fetchProgressSuccess(result.data.data.list))
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* root() {
  yield all([
    takeLeading(actions.uploadPhoto.toString(), uploadPhoto),
    takeLeading(actions.fetchUpload.toString(), fetchUpload),
    takeLeading(actions.generatePhoto.toString(), generatePhoto),
    takeLeading(actions.fetchProgress.toString(), fetchProgress)
  ])
}
