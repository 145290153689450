import Request from '@/lib/api/request'

export const uploadUserPhoto = async photo => {
  const formData = new FormData()
  formData.append('image', photo.originFileObj)
  const response = await Request.post('image/upload')
    .useAuth()
    .header('Content-Type', 'multipart/form-data')
    .body(formData)
    .send()
  return response
}

export const fetchUserUpload = async () => {
  const response = await Request.post('image/fetch-history')
    .body({
      thumbnail: {
        maxWidth: '800',
        type: 'webp'
      }
    })
    .useAuth()
    .send()
  return response
}

export const genUserPhoto = async ({ photo, theme }) => {
  const response = await Request.post('phone-case/generate-image')
    .body({
      imageId: photo,
      themeId: theme,
      thumbnail: {
        maxWidth: '800',
        type: 'webp'
      }
    })
    .useAuth()
    .send()
  return response
}

export const fetchPhotoProgress = async () => {
  const response = await Request.post('phone-case/fetch-upload-progress')
    .body({
      thumbnail: {
        maxWidth: '800',
        type: 'webp'
      }
    })
    .useAuth()
    .send()
  return response
}
