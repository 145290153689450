import { handleActions } from 'redux-actions'
import actions from './action'

const initialState = {
  uploading: false,
  processing: false,
  loading: true,
  upload: [],
  gallery: [],
  targetPhoto: null
}

export default handleActions(
  {
    [actions.uploadPhoto]: state => {
      return {
        ...state,
        uploading: true
      }
    },
    [actions.uploadPhotoSuccess]: state => {
      return {
        ...state,
        uploading: false
      }
    },
    [actions.fetchUploadSuccess]: (state, { payload }) => {
      return {
        ...state,
        upload: payload
      }
    },
    [actions.generatePhoto]: state => {
      return {
        ...state,
        processing: true
      }
    },
    [actions.generatePhotoSuccess]: state => {
      return {
        ...state,
        processing: false
      }
    },
    [actions.fetchProgress]: state => {
      return {
        ...state,
        loading: true
      }
    },
    [actions.fetchProgressSuccess]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        gallery: payload
      }
    },
    [actions.setPhoto]: (state, { payload }) => {
      return {
        ...state,
        targetPhoto: payload
      }
    },
    [actions.clearPage]: (state, { payload }) => {
      return {
        ...state,
        gallery: [],
        targetPhoto: null
      }
    }
  },
  initialState
)
