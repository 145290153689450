import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import createRootReducer from './reducer'
import rootSaga from './saga'

const sagaMiddleware = createSagaMiddleware()

export const makeStore = () => {
  const store = configureStore({
    reducer: createRootReducer(),
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware().concat(sagaMiddleware)
    }
  })

  store.sagaTask = sagaMiddleware.run(function* () {
    yield rootSaga()
  })

  return store
}
