import general from './general/reducer'
import theme from './theme/reducer'
import category from './category/reducer'
import user from './user/reducer'
import cart from './cart/reducer'

export default () => {
  return {
    general,
    theme,
    category,
    user,
    cart
  }
}
