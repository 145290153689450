'use client'
import { makeStore } from '@/lib/store'
import { useRef } from 'react'
import { Provider } from 'react-redux'
import { StyleProvider } from '@ant-design/cssinjs'

export default function StoreProvider({ children }) {
  const storeRef = useRef(null)
  if (!storeRef.current) {
    if (typeof window !== 'undefined' && window.createStore) {
      storeRef.current = window.createStore
    } else {
      storeRef.current = makeStore()
      if (typeof window !== 'undefined') window.createStore = storeRef.current
    }
  }

  return (
    <Provider store={storeRef.current}>
      <StyleProvider layer>{children}</StyleProvider>
    </Provider>
  )
}
