import { handleActions } from 'redux-actions'
import actions from './action'

const initialState = {
  phoneCase: null,
  sample: null
}

export default handleActions(
  {
    [actions.setPhoneCase]: (state, { payload }) => {
      return {
        ...state,
        phoneCase: payload
      }
    },
    [actions.setSample]: (state, { payload }) => {
      return {
        ...state,
        sample: payload
      }
    },
    [actions.clearPage]: state => {
      return {
        ...state,
        sample: null
      }
    }
  },
  initialState
)
