import { createActions } from 'redux-actions'

export default createActions(
  'UPLOAD_PHOTO',
  'UPLOAD_PHOTO_SUCCESS',
  'UPLOAD_PHOTO_FAIL',
  'FETCH_UPLOAD',
  'FETCH_UPLOAD_SUCCESS',
  'FETCH_UPLOAD_FAIL',
  'GENERATE_PHOTO',
  'GENERATE_PHOTO_SUCCESS',
  'GENERATE_PHOTO_FAIL',
  'FETCH_PROGRESS',
  'FETCH_PROGRESS_SUCCESS',
  'FETCH_PROGRESS_FAIL',
  'SET_PHOTO',
  'CLEAR_PAGE',
  {
    prefix: 'USER'
  }
)
