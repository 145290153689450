'use client'

import { useEffect } from 'react'
import { connect } from 'react-redux'
import cartActions from '@/lib/cart/action'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import Icon from '@/components/shared/Icon'
import { Badge } from 'antd'

const Cart = ({ cdn, totalQuantity, fetchCart, isMobile }) => {
  useEffect(() => {
    fetchCart()
  }, [])
  const pathname = usePathname()
  return pathname === '/cart' ? (
    <></>
  ) : (
    <div
      className={`${isMobile ? 'header-icons-mobile md:hidden' : 'hidden md:header-icons-desktop'}`}
    >
      <Link href={'/cart'}>
        <Badge count={totalQuantity / 2}>
          {isMobile ? (
            totalQuantity > 0 && (
              <Icon
                className="w-14"
                cdn={cdn}
                url={'/asset/images/layout/mobile/cart.svg'}
              />
            )
          ) : (
            <Icon
              className="w-7"
              cdn={cdn}
              url={'/asset/images/layout/desktop/cart.svg'}
            />
          )}
        </Badge>
      </Link>
    </div>
  )
}

export default connect(
  state => ({
    ...state.cart
  }),
  { ...cartActions }
)(Cart)
