import { createActions } from 'redux-actions'

export default createActions(
  'SET_EXPAND_FILTER',
  'SET_FILTER',
  'CLEAR_FILTER',
  {
    prefix: 'CATEGORY'
  }
)
