'use client'

import Link from 'next/link'
import Icon from '@/components/shared/Icon'

export default function SocialLink({ type, text, link, cdn, icon }) {
  return (
    <Link
      className="font-normal text-sm md:text-base mb-2"
      href={'#'}
      onClick={e => {
        e.preventDefault()
        if (type === 'link') window.open(link, '_blank')
        else if (type === 'mail') window.location.href = `mailto:${link}`
      }}
    >
      <div className="flex items-center" title={`${text}`}>
        <Icon className="w-[2rem] mr-3" cdn={cdn} url={icon} />
        {/* <div className="mt-[0.3rem]">{text}</div> */}
      </div>
    </Link>
  )
}
