import { all, put, takeLeading } from 'redux-saga/effects'
import actions from './action'
import {
  fetchCartData,
  addProduct,
  updateProduct,
  removeProduct,
  updateDiscount
} from './api'

function* fetchCart() {
  try {
    const result = yield fetchCartData()
    if (result.status === 200) {
      yield put(actions.fetchCartSuccess(result.data.data))
    }
  } catch (error) {
    console.log(error)
  }
}

function* applyDiscount({ payload }) {
  try {
    const result = yield updateDiscount(payload)
    if (result.status === 200) {
      yield put(actions.applyDiscountSuccess(result.data.data))
    }
  } catch (error) {
    console.log(error)
    yield put(actions.fetchCart())
    yield put(actions.applyDiscountFail())
  }
}

function* addItem({ payload }) {
  try {
    const result = yield addProduct(payload)
    if (result.status === 200) {
      yield put(actions.addItemSuccess(result.data.data))
    }
  } catch (error) {
    console.log(error)
    yield put(actions.fetchCart())
    yield put(actions.addItemFail())
  }
}

function* updateItem({ payload }) {
  try {
    const result = yield updateProduct(payload)
    if (result.status === 200) {
      yield put(actions.updateItemSuccess(result.data.data))
    }
  } catch (error) {
    console.log(error)
    yield put(actions.fetchCart())
    yield put(actions.updateItemFail())
  }
}

function* removeItem({ payload }) {
  try {
    const result = yield removeProduct(payload)
    if (result.status === 200) {
      yield put(actions.removeItemSuccess(result.data.data))
    }
  } catch (error) {
    console.log(error)
    yield put(actions.fetchCart())
    yield put(actions.removeItemFail())
  }
}

export default function* root() {
  yield all([
    takeLeading(actions.fetchCart.toString(), fetchCart),
    takeLeading(actions.applyDiscount.toString(), applyDiscount),
    takeLeading(actions.addItem.toString(), addItem),
    takeLeading(actions.updateItem.toString(), updateItem),
    takeLeading(actions.removeItem.toString(), removeItem)
  ])
}
