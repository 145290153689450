import { handleActions, combineActions } from 'redux-actions'
import actions from './action'

const initialState = {
  lines: [],
  checkoutUrl: null,
  totalQuantity: 0,
  cost: null,
  discountAllocations: [],
  discountCodes: [],
  loading: true,
  updating: false
}

export default handleActions(
  {
    [actions.fetchCart]: state => {
      return {
        ...state,
        loading: true
      }
    },
    [actions.fetchCartSuccess]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        loading: false
      }
    },
    [combineActions(
      actions.applyDiscount,
      actions.addItem,
      actions.updateItem,
      actions.removeItem
    )]: state => {
      return {
        ...state,
        updating: true
      }
    },
    [combineActions(
      actions.applyDiscountSuccess,
      actions.addItemSuccess,
      actions.updateItemSuccess,
      actions.removeItemSuccess
    )]: (state, { payload }) => {
      return { ...state, ...payload, updating: false }
    },
    [combineActions(
      actions.applyDiscountFail,
      actions.addItemFail,
      actions.updateItemFail,
      actions.removeItemFail
    )]: state => {
      return { ...state, updating: false }
    }
  },
  initialState
)
