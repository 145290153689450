'use client'
import { useState } from 'react'
import { MenuUnfoldOutlined, CloseOutlined } from '@ant-design/icons'
import getLocale from '@/lib/utilities/getLocale'
import Font from '@/fonts/font'
import MenuLink from '../menu/link'

export default function SideMenu({ menu, text }) {
  const locale = getLocale(text)
  const [sideMenu, setSideMenu] = useState()
  return (
    <>
      <div
        className="flex md:hidden items-center"
        onClick={() => {
          setSideMenu(true)
        }}
      >
        <MenuUnfoldOutlined
          className="mr-2 text-xl"
          style={{ color: 'white' }}
        />
      </div>
      <div
        className={`overflow-y-auto block md:hidden fixed top-0 right-0 h-[100dvh] w-[16rem] slider-right ${sideMenu === true ? 'slide-in' : sideMenu === false ? 'slide-out-right' : ''}`}
        style={{ background: '#266599', zIndex: '32' }}
      >
        <div
          className="h-12 w-full flex items-center justify-between"
          style={{ background: '#174b75' }}
        >
          <div className={`ml-4 text-lg text-white ${Font.Myriad}`}>
            {locale['menu/menu']}
          </div>
          <CloseOutlined
            className="text-2xl mr-4 cursor-pointer"
            style={{ color: 'white' }}
            onClick={() => {
              setSideMenu(false)
            }}
          />
        </div>
        <div>
          {menu.items &&
            menu.items.map(item => {
              return (
                <div
                  className={`flex text-xl text-white justify-center items-center ${Font.Myriad}`}
                  key={item.key}
                  style={{ borderBottom: '1px solid #8596a4' }}
                >
                  {item.link ? (
                    <MenuLink
                      item={item}
                      text={locale[item.text]}
                      click={() => {
                        setSideMenu(false)
                      }}
                    />
                  ) : (
                    <div className="py-5 md:py-0 ">{locale[item.text]}</div>
                  )}
                </div>
              )
            })}
        </div>
      </div>
      {sideMenu && (
        <div
          className="fixed top-0 left-0 h-[100dvh] w-[100dvw] opacity-70 block md:hidden"
          style={{ background: '#000000', zIndex: '31' }}
          onClick={() => {
            setSideMenu(false)
          }}
        ></div>
      )}
    </>
  )
}
